import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "filters" }
const _hoisted_2 = { class: "filters__values" }
const _hoisted_3 = {
  key: 0,
  class: "filters__values__item"
}
const _hoisted_4 = { class: "filters__inputs" }
const _hoisted_5 = { class: "filters__input__group__list__head" }
const _hoisted_6 = { class: "filters__input__group__list__head__title" }
const _hoisted_7 = { class: "filters__input__group__list__body" }
const _hoisted_8 = { class: "filters__input__group__list__body__item" }
const _hoisted_9 = { class: "filters__input__group__list__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_FilterItem = _resolveComponent("FilterItem")!
  const _component_FilterSearchInput = _resolveComponent("FilterSearchInput")!
  const _component_DateRangePicker = _resolveComponent("DateRangePicker")!
  const _component_BaseRadio = _resolveComponent("BaseRadio")!
  const _component_BaseLabel = _resolveComponent("BaseLabel")!
  const _component_FilterInputGroup = _resolveComponent("FilterInputGroup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.selecting.organization)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("span", null, _toDisplayString(_ctx.hospitalName), 1),
            _createElementVNode("button", {
              disabled: "",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.removeFilter('organization', null)))
            }, [
              _createVNode(_component_BaseIcon, { icon: "filter/close" })
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.filters.department)
        ? (_openBlock(), _createBlock(_component_FilterItem, {
            key: 1,
            filter: "department",
            scope: _ctx.filters.department,
            onRemove_filter: _ctx.removeFilter
          }, null, 8, ["scope", "onRemove_filter"]))
        : _createCommentVNode("", true),
      (_ctx.filters.category)
        ? (_openBlock(), _createBlock(_component_FilterItem, {
            key: 2,
            filter: "category",
            scope: _ctx.filters.category,
            onRemove_filter: _ctx.removeFilter
          }, null, 8, ["scope", "onRemove_filter"]))
        : _createCommentVNode("", true),
      (
          _ctx.filters.subCategory &&
          _ctx.allowedFilters.includes(_ctx.FilterScopeEnums.SUBCATEGORY)
        )
        ? (_openBlock(), _createBlock(_component_FilterItem, {
            key: 3,
            filter: "subCategory",
            scope: _ctx.filters.subCategory,
            onRemove_filter: _ctx.removeFilter
          }, null, 8, ["scope", "onRemove_filter"]))
        : _createCommentVNode("", true),
      (
          _ctx.filters.vendor && _ctx.allowedFilters.includes(_ctx.FilterScopeEnums.VENDOR)
        )
        ? (_openBlock(), _createBlock(_component_FilterItem, {
            key: 4,
            filter: "vendor",
            keyName: "brand_name",
            scope: _ctx.filters.vendor,
            onRemove_filter: _ctx.removeFilter
          }, null, 8, ["scope", "onRemove_filter"]))
        : _createCommentVNode("", true),
      _withDirectives(_createVNode(_component_FilterItem, {
        isObject: false,
        filter: "deviceStatuses",
        scope: _ctx.filters.deviceStatuses,
        onRemove_filter: _ctx.removeFilter,
        disabled: _ctx.disableStatus
      }, null, 8, ["scope", "onRemove_filter", "disabled"]), [
        [_vShow, 
          _ctx.filters.deviceStatuses &&
          _ctx.allowedFilters.includes(_ctx.FilterScopeEnums.DEVICE_STATUSES)
        ]
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters.reconciliationStatuses, (status, index) => {
        return _withDirectives((_openBlock(), _createBlock(_component_FilterItem, {
          key: index,
          index: index,
          isObject: false,
          filter: "reconciliationStatuses",
          scope: status,
          onRemove_filter: _ctx.removeFilter
        }, null, 8, ["index", "scope", "onRemove_filter"])), [
          [_vShow, 
          _ctx.filters.reconciliationStatuses &&
          _ctx.filters.reconciliationStatuses.length > 0 &&
          _ctx.allowedFilters.includes(_ctx.FilterScopeEnums.RECONCILIATION_STATUSES)
        ]
        ])
      }), 128)),
      (
          _ctx.filters.locationUnit &&
          _ctx.allowedFilters.includes(_ctx.FilterScopeEnums.LOCATION_UNIT)
        )
        ? (_openBlock(), _createBlock(_component_FilterItem, {
            key: 5,
            filter: "locationUnit",
            scope: _ctx.filters.locationUnit,
            onRemove_filter: _ctx.removeFilter
          }, null, 8, ["scope", "onRemove_filter"]))
        : _createCommentVNode("", true),
      (_ctx.searchString)
        ? (_openBlock(), _createBlock(_component_FilterItem, {
            key: 6,
            "is-object": false,
            filter: "searchString",
            scope: _ctx.searchString,
            onRemove_filter: _ctx.removeFilter
          }, null, 8, ["scope", "onRemove_filter"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.showSearch)
        ? (_openBlock(), _createBlock(_component_FilterSearchInput, { key: 0 }))
        : _createCommentVNode("", true),
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleFilter && _ctx.toggleFilter(...args))),
        class: "modals-trigger filters__input__group"
      }, [
        _createVNode(_component_BaseIcon, { icon: "filter/filter-alt" })
      ]),
      (_ctx.showDatePicker)
        ? (_openBlock(), _createBlock(_component_DateRangePicker, {
            key: 1,
            onDate_updated: _ctx.dateUpdated
          }, null, 8, ["onDate_updated"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["modals filters__input__group__list", { active: _ctx.showFilter }])
      }, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _cache[5] || (_cache[5] = _createElementVNode("h3", null, "Filters", -1)),
            _createElementVNode("a", {
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.clearFilter && _ctx.clearFilter(...args)))
            }, "Clear all")
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("button", {
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectFilter(0))),
              class: "filters__input__group__list__body__item__title"
            }, [
              _cache[6] || (_cache[6] = _createElementVNode("span", null, "Organizations", -1)),
              _createVNode(_component_BaseIcon, { icon: "expand-more" })
            ]),
            _createElementVNode("div", {
              class: _normalizeClass(["filters__input__group__list__body__item__list", { active: _ctx.activeFilter === 0 }])
            }, [
              _createElementVNode("div", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hospitals, (hospital, index2) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index2,
                    class: "filters__input__group__item"
                  }, [
                    _createVNode(_component_BaseRadio, { checked: true }),
                    _createVNode(_component_BaseLabel, {
                      title: hospital.name
                    }, null, 8, ["title"])
                  ]))
                }), 128))
              ])
            ], 2)
          ]),
          _createVNode(_component_FilterInputGroup, {
            name: "Departments",
            index: 1,
            activeFilter: _ctx.activeFilter,
            filter: "department",
            scopeList: _ctx.departments,
            selecting: _ctx.selecting,
            onCheck: _ctx.check,
            onSelect_filter: _ctx.selectFilter
          }, null, 8, ["activeFilter", "scopeList", "selecting", "onCheck", "onSelect_filter"]),
          _createVNode(_component_FilterInputGroup, {
            name: "Categories",
            index: 2,
            activeFilter: _ctx.activeFilter,
            filter: "category",
            show: Boolean(_ctx.selecting.department),
            scopeList: _ctx.categories,
            selecting: _ctx.selecting,
            onCheck: _ctx.check,
            onSelect_filter: _ctx.selectFilter
          }, null, 8, ["activeFilter", "show", "scopeList", "selecting", "onCheck", "onSelect_filter"]),
          _withDirectives(_createVNode(_component_FilterInputGroup, {
            name: "Subcategories",
            index: 3,
            activeFilter: _ctx.activeFilter,
            filter: "subCategory",
            show: Boolean(_ctx.selecting.department && _ctx.selecting.category),
            scopeList: _ctx.subCategories,
            selecting: _ctx.selecting,
            onCheck: _ctx.check,
            onSelect_filter: _ctx.selectFilter
          }, null, 8, ["activeFilter", "show", "scopeList", "selecting", "onCheck", "onSelect_filter"]), [
            [_vShow, _ctx.allowedFilters.includes(_ctx.FilterScopeEnums.SUBCATEGORY)]
          ]),
          _withDirectives(_createVNode(_component_FilterInputGroup, {
            name: "Vendors",
            index: 4,
            activeFilter: _ctx.activeFilter,
            filter: "vendor",
            scopeList: _ctx.vendors,
            scopeObjName: "brand_name",
            selecting: _ctx.selecting,
            onCheck: _ctx.check,
            onSelect_filter: _ctx.selectFilter
          }, null, 8, ["activeFilter", "scopeList", "selecting", "onCheck", "onSelect_filter"]), [
            [_vShow, _ctx.allowedFilters.includes(_ctx.FilterScopeEnums.VENDOR)]
          ]),
          _withDirectives(_createVNode(_component_FilterInputGroup, {
            name: "Status",
            index: 6,
            activeFilter: _ctx.activeFilter,
            filter: "deviceStatuses",
            scopeList: _ctx.deviceStatuses,
            objectArray: false,
            isGrouped: false,
            selecting: _ctx.selecting,
            onCheck: _ctx.check,
            onSelect_filter: _ctx.selectFilter
          }, null, 8, ["activeFilter", "scopeList", "selecting", "onCheck", "onSelect_filter"]), [
            [_vShow, _ctx.allowedFilters.includes(_ctx.FilterScopeEnums.DEVICE_STATUSES)]
          ]),
          _withDirectives(_createVNode(_component_FilterInputGroup, {
            name: "Reconciliation Status",
            index: 5,
            activeFilter: _ctx.activeFilter,
            filter: "reconciliationStatuses",
            scopeList: _ctx.reconciliationStatusesGroupedList,
            objectArray: false,
            multiple: true,
            isGrouped: true,
            selecting: _ctx.selecting,
            onCheck: _ctx.check,
            onSelect_filter: _ctx.selectFilter
          }, null, 8, ["activeFilter", "scopeList", "selecting", "onCheck", "onSelect_filter"]), [
            [_vShow, 
              _ctx.allowedFilters.includes(_ctx.FilterScopeEnums.RECONCILIATION_STATUSES)
            ]
          ]),
          _createVNode(_component_FilterInputGroup, {
            name: "Unit",
            index: 7,
            activeFilter: _ctx.activeFilter,
            filter: "locationUnit",
            scopeList: _ctx.roomsUnits,
            selecting: _ctx.selecting,
            onCheck: _ctx.check,
            onSelect_filter: _ctx.selectFilter
          }, null, 8, ["activeFilter", "scopeList", "selecting", "onCheck", "onSelect_filter"])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("button", {
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.applyFilters && _ctx.applyFilters(...args))),
            class: "filters__input__group__list__button"
          }, " Apply Filters ")
        ])
      ], 2)
    ])
  ]))
}